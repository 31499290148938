<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet v-bind:title="'Best Sellers'">
          <template v-slot:title>
            Ученики
          </template>
          <template v-slot:toolbar>
            
          </template>
          <template v-slot:body>
            <b-form>
              <div class="row">
                <div class="col-3">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.year") }}
                    </template>
                    <b-form-select
                        v-model="filter.educationYear"
                        :options="educationYears"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-3" v-if="showMainDepartment()">
                  <b-form-group>
                    <template v-slot:label>
                      Район
                    </template>
                    <b-form-select
                        v-model="filter.regionId"
                        :options="regions"
                        @change="reloadSchools"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group>
                    <template v-slot:label>
                      Школа
                    </template>
                    <b-form-select
                        v-model="filter.schoolId"
                        :options="schools"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentGroups.list.level") }}
                    </template>
                    <b-form-select
                        v-model="filter.level"
                        :options="levels"
                        @change="reloadGroups"
                    ></b-form-select>
                  </b-form-group>
                </div>    
                <div class="col-3">
                  <b-form-group>
                    <template v-slot:label>
                      Класс
                    </template>
                    <b-form-select
                        v-model="filter.groupId"
                        :options="groups"
                    ></b-form-select>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group>
                    <template v-slot:label>
                      Инн
                    </template>
                    <b-form-input                        
                        v-model="filter.inn"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group>
                    <template v-slot:label>
                      Фамилия
                    </template>
                    <b-form-input
                        v-model="filter.firstName"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group>
                    <template v-slot:label>
                      Имя
                    </template>
                    <b-form-input
                        v-model="filter.name"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group>
                    <template v-slot:label>
                      Адрес
                    </template>
                    <b-form-input
                        v-model="filter.address"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-3">
                  <b-form-group>
                    <template v-slot:label>
                      Язык обучения
                    </template>
                    <b-form-select
                        v-model="filter.languageOfInstructionId"
                        :options="languageOfInstructions"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <b-button type="button" @click="reloadData" variant="primary"
                        :class="{'kt-spinner kt-spinner--light disabled':loading }">{{
                  $t("common.filter")
                }}
              </b-button>

              <b-button type="button" @click="exportData" variant="primary" style="margin-left: 10px">Экспорт</b-button>
            </b-form>

            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th>#</th>
                <th class="text-left">
                  {{ $t("studentsList.inn") }}
                </th>      
                <th class="text-left">
                  {{ $t("studentsList.schoolName") }}
                </th>
                <th class="text-left">
                  {{ $t("studentsList.firstName") }}
                </th>
                <th class="text-left">{{ $t("studentsList.name") }}</th>
                <th class="text-left">
                  {{ $t("studentsList.patronymic") }}
                </th>   
                <th class="text-left">
                  Класс
                </th>
                <th class="text-left">
                  {{ $t("studentsList.contactPhone") }}
                </th>
                <th class="text-left">
                  {{ $t("studentsList.sex") }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, k) in students" :key="item.id" :class="isDropped(item)">
                <td>{{ k + 1 }}</td>
                <td>{{ item.inn }}</td>
                <td>{{ item.schoolName }}</td>
                <td>{{ item.firstName }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.patronymic }}</td>
                <td>{{ item.groupName }}</td>
                <td>{{ item.contactPhone }}</td>
                <td>{{ showSex(item.sex) }}</td>
                <td>
                  <a class="btn btn-icon btn-success" :href="'#/studentInfo/' + item.id">
                    <i class="fa fa-pen"></i>
                  </a>
                </td>
              </tr>
              </tbody>
            </table>
            
            <Pagination v-bind:loading="loading" v-bind:total="pageModel.Total" v-bind:current="pageModel.Showed" v-bind:perpage="filter.perPage"
                        v-on:open-page="openPage" v-on:per-page-change="perPageChange" ref="cartPager">
              
            </Pagination>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import Pagination from "@/views/pages/edu/common/Pagination.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";
import {mapGetters} from "vuex";

export default {
  name: "EducationDepartmentStudents",
  components: {
    KTPortlet,
    Pagination
  },
  data() {
    return {
      loading: false,
      length: 10,
      pageModel:{
        Total: 1000,
        Showed: 10
      },
      filter:{
        educationYear: DictionariesService.currentYear(),
        regionId: 0,
        page:1,
        perPage: 10,
        languageOfInstructionId: 0
      },
      groups: [],
      levels: DictionariesService.levelOptions(),
      educationYears: DictionariesService.educationYearOptions(),
      shifts: DictionariesService.shiftOptions(),
      sexOptions:DictionariesService.sexOptions(),
      students: [],
      schools:[],
      regions:[],
      languageOfInstructions: [],
    };
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  mounted() {
    let $this = this;
    ApiService.querySecured("dictionaries/languageOfInstruction")
        .then(({data}) => {
          $this.languageOfInstructions = data.dictionary;
          $this.languageOfInstructions.unshift({value: 0, text: ""})
        }).catch(({response}) => {
      console.log(response);
    });
    
    ApiService.querySecured("dictionaries/regions", {}).then(({data}) => {
      $this.regions = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
    
    ApiService.querySecured("dictionaries/schools", {}).then(({data}) => {
      $this.schools = data.dictionary;
    }).catch(({response}) => {
      console.log(response);
    });
    
    this.reloadData();
  },
  methods: {
    openPage(page){
      this.filter.page = page;
      
      this.reloadData();
    },
    perPageChange(perPage){
      this.filter.perPage = perPage;

      this.reloadData();
    },
    reloadData(){
      let $this = this;
      $this.loading = true;
      ApiService.postSecured("educationDepartment/students", this.filter).then(({data}) => {
        $this.students = data.list;
        $this.pageModel.Showed = data.showed;
        $this.pageModel.Total = data.total;
      }).catch(({response}) => {
        console.log(response);
      }).finally(function (){
        $this.loading = false;
      });

      this.reloadGroups();
      
    },
    showSex(sexId){
      for (let i in this.sexOptions){
        let sex = this.sexOptions[i];

        if(sex.value == sexId){
          return sex.text;
        }
      }

      return '';
    },
    isDropped(student) {
      return student.isDropped === true ? "table-danger" : "";
    },
    reloadSchools(){
      ApiService.querySecured("dictionaries/schools",
          {params: {regionId: this.filter.regionId}})
          .then(({data}) => {
            data.dictionary.unshift({ value: 0, text: '' });
            this.schools = data.dictionary;
          }).catch(({response}) => {
        console.log(response);
      });
    },
    reloadGroups(){
      ApiService.querySecured("dictionaries/groupsForFilter", 
          {params: {educationYear: this.filter.educationYear, level: this.filter.level, schoolId: this.filter.schoolId}})
          .then(({data}) => {
            data.dictionary.unshift({ value: 0, text: '' });
        this.groups = data.dictionary;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    showMainDepartment(){
      return this.currentUser.role == 'ROLE_MAIN_EDUCATION_DEPARTMENT';
    },
    exportData(){

      ApiService.downloadSecured("educationDepartment/exportStudents", this.filter).then(({data}) => {
        const url = URL.createObjectURL(new Blob([data], {
          type: 'application/vnd.ms-excel'
        }));
        const link = document.createElement('a');
        link.href = url;

        link.setAttribute('download', 'Ученики.xlsx');
        document.body.appendChild(link);
        link.click();
      }).catch(({response}) => {
        console.log(response);
      });
    },
  },

  
};
</script>
